.box {
  width: 200px; height: 300px;
  position: absolute;
  border: 1px solid #BBB;
  background: #EEE;
}

.ribbon, .news-ribbon {
  position: absolute;
  left: -5px; top: -5px;
  @media (min-width: @screen-sm-min) {
    left: 1px; top: -5px;
  }
  @media (min-width: @screen-md-min) {
    left: 1px; top: -5px;
  }
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}

.hotel-detail-ribbon, .hotel-detail-news-ribbon {
  position: absolute;
  left: 10px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}

.selected-hotel-ribbon, .selected-hotel-news-ribbon {
  position: absolute;
  left: -5px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}

.selected-hotel-ribbon-single, .selected-hotel-news-ribbon-single {
  position: absolute;
  left: 1px; top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px; height: 75px;
  text-align: right;
}

.ribbon span, .hotel-detail-ribbon span, .selected-hotel-ribbon span, .selected-hotel-ribbon-single span {
  font-size: 10px;
  font-weight: bold;
  color: #FFF;
  //text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#F70505 0%, #FF214E 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px; left: -21px;
}

.news-ribbon span, .hotel-detail-news-ribbon span, .selected-hotel-news-ribbon span, .selected-hotel-news-ribbon-single span {
  &:extend(.ribbon span);
  background: linear-gradient(#e7a200 0%, #f7b205 100%);
}

.ribbon span::before, .hotel-detail-ribbon span::before, .selected-hotel-ribbon span::before, .selected-hotel-ribbon-single span::before {
  content: "";
  position: absolute; left: 0; top: 100%;
  z-index: -1;
  border-left: 3px solid #FF214E;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #FF214E;
}

.news-ribbon span::before, .hotel-detail-news-ribbon span::before, .selected-hotel-news-ribbon span::before, .selected-hotel-news-ribbon-single span::before {
  &:extend(.ribbon span::before);
  border-left: 3px solid #e7a200;
  border-top: 3px solid #e7a200;
}

.ribbon span::after, .hotel-detail-ribbon span::after, .selected-hotel-ribbon span::after, .selected-hotel-ribbon-single span::after {
  content: "";
  position: absolute; right: 0; top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #FF214E;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #FF214E;
}

.news-ribbon span::after, .hotel-detail-news-ribbon span::after, .selected-hotel-news-ribbon span::after, .selected-hotel-news-ribbon-single span::after {
  &:extend(.ribbon span::after);
  border-right: 3px solid #e7a200;
  border-top: 3px solid #e7a200;
}
