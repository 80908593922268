#carousel-hotel-images .item {
  height: 194px;
  padding-left: -20px;
  @media (min-width: @screen-sm-min) {
    height: 410px;
  }
  @media (min-width: @screen-md-min) {
    height: 336px;
  }
  @media (min-width: @screen-lg-min) {
    height: 410px;
  }
}

.carousel-inner img {
  display: block;
  margin: auto;
}

.carousel-choose-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
