@arrow-size: 20px;
@arrow-sep: 6px;

.arrow(@color) {
  width: 0px;
  height: 0px;
  border-top: @arrow-size inset transparent;
  border-bottom: @arrow-size inset transparent;
  border-left: @arrow-size solid @color;
  position: absolute;
  content: "";
  top: 0;
}

// Nav Wizard
// From github.com/acornejo/bootstrap-nav-wizard
// -------------------------
.nav-wizard {
  padding: 0 0 20px 0;

  > li {
    width: 220px;
    float: none;
    display: inline-block;
    *display:inline; /* ie7 fix */
    zoom:1; /* hasLayout ie7 trigger */


    > a {
      position: relative;
      background-color: #fff;

      .badge {
        margin-left: 3px;
        color: @nav-link-hover-bg;
        background-color: @badge-active-color;
      }
    }
    &:not(:first-child) > a {
      padding-left: 2*@arrow-size - @arrow-sep;

      &:before {
        .arrow(@me-bg-color);
        left: 0;
      }
    }
    &:not(:last-child) > a {
      margin-right: @arrow-sep;

      &:after {
        .arrow(#fff);
        right: -@arrow-size;
        z-index: 2;
      }
    }
    &:first-child > a {
      border-top-left-radius: @nav-pills-border-radius;
      border-bottom-left-radius: @nav-pills-border-radius;
    }

    &:last-child > a {
      border-top-right-radius: @nav-pills-border-radius;
      border-bottom-right-radius: @nav-pills-border-radius;
    }

    &.done:hover > a, &:hover > a {
      background-color: darken(@nav-link-hover-bg, 10%);
      &:before {
        border-right-color: darken(@nav-link-hover-bg, 10%);
      }
      &:after {
        border-left-color: darken(@nav-link-hover-bg, 10%);
      }
    }

    &.done > a {
      background-color: darken(@nav-link-hover-bg, 5%);

      &:before {
        border-right-color: darken(@nav-link-hover-bg, 5%);
      }
      &:after {
        border-left-color: darken(@nav-link-hover-bg, 5%);
      }
    }

    &.active > a {
      &,
      &:hover,
      &:focus {
        color: #fff;
        background-color: @me-main-color;
      }

      &:after {
        border-left-color: @me-main-color;
      }

      .badge {
        color: @badge-active-color;
        background-color: @badge-active-bg;
      }
    }

    &.disabled > a {
      color: @nav-disabled-link-color;
      background-color: #eee;

      &:hover,
      &:focus {
        color: @nav-disabled-link-color;
        text-decoration: none;
        background-color: #eee;
        cursor: default;
      }
      &:before {
        border-right-color: @nav-link-hover-bg;
      }
      &:after {
        border-left-color: #eee;
      }
    }

    // &.active ~ li > a {
    //   color: @nav-disabled-link-color;
    //   &:hover,
    //   &:focus {
    //     color: @nav-disabled-link-hover-color;
    //     text-decoration: none;
    //     background-color: transparent;
    //     cursor: not-allowed;
    //   }
    //     // background-color: darken(@nav-link-hover-bg, 20%);
    //     // &:before {
    //     //     border-right-color: darken(@nav-link-hover-bg, 20%);
    //     // }
    //     // &:after {
    //     //     border-left-color: darken(@nav-link-hover-bg, 20%);
    //     // }
    // }
  }
}

.nav-wizard.nav-justified {
    > li {
        float: none;

        > a {
            padding: @nav-link-padding;
        }
    }

    // Small screens size, remove the "arrows" and just show it like stacked pills.
    @media (max-width: @screen-sm-min) {
        > li > a {
            border-radius: @nav-pills-border-radius;
            margin-right: 0;

            &:before,
            &:after {
              border: none !important;
            }
        }
    }
}