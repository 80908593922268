html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  margin-bottom: 162px;
  padding-top: 60px;
  @media (min-width: @screen-xs-min) {
    margin-bottom: 124px;
    padding-top: 86px;
  }
  @media (min-width: @screen-sm-min) {
    margin-bottom: 86px;
    padding-top: 86px;
  }
  @media (min-width: @screen-md-min) {
    margin-bottom: 86px;
  }
  background-color: @me-bg-color;
  font-size: 15px;
}

.main-page-body {
  /* Margin bottom by footer height */
  margin-bottom: 162px;
  padding-top: 60px;
  @media (min-width: @screen-xs-min) {
    margin-bottom: 124px;
    padding-top: 86px;
  }
  @media (min-width: @screen-sm-min) {
    margin-bottom: 86px;
    padding-top: 86px;
  }
  @media (min-width: @screen-md-min) {
    margin-bottom: 86px;
    padding-top: 48px;
  }
}

a {
  color: @me-main-color;
}

a:hover {
  color: @me-main-color-darker;
}

.alert {
  @media (max-width: @screen-xs-max) {
    margin-bottom: 0;
  }
}

.row {
  @media (max-width: @screen-sm-max) {
    margin-left: 14px;
    margin-right: 14px;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-top: 14px;
  /* Set the fixed height of the footer here */
  height: 162px;
  @media (min-width: @screen-xs-min) {
    height: 124px;
  }
  @media (min-width: @screen-md-min) {
    height: 86px;
  }
  background-color: @me-main-color;
  color: #fff;
  text-align: center;
  a {
    color: inherit;
  }
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: @me-main-color;
  height: 136px;
  @media (min-width: @screen-md-min) {
    padding-top: 16px;
    height: 86px;
  }
  color: #fff;
  text-align: center;
  a {
    color: inherit;
  }
}

.navbar-fixed-top {
  height: 66px;
  padding: 0 12px 0 12px;
  @media (min-width: @screen-sm-min) {
    padding: 0 36px 0 30px;
  }
  @media (min-width: @screen-md-min) {
    padding: 0 72px 0 30px;
  }
}

.navbar-brand {
  padding-top: 10px;
}

.navbar-default {
  background-color: #ffffff;
  font-size: 16px;
  //border-color: darkturquoise;
  border-width: 0 0 3px 0;
}

.navbar-default-front {
  border-color: white;
  border-width: 0 0 3px 0;
}

.navbar-default .navbar-nav > li {
  padding-top: 8px;
}

.navbar-default .navbar-nav > li > a {
  color: #888;
}

.navbar-default .navbar-header > a {
  color: #888;
}

@grid-float-breakpoint: 1100px;
//** Point at which the navbar begins collapsing.
@grid-float-breakpoint-max: (@grid-float-breakpoint - 1);

.navbar-collapse {
  background: #ffffff;
  @media (max-width: @grid-float-breakpoint) {
    margin-top: 20px;
    border: 1px solid transparent;
  }
}

select.square-select {
  color: white;
  background: @me-main-color;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  -webkit-border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAACFmlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS41LjAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgeG1sbnM6YXV4PSJodHRwOi8vbnMuYWRvYmUuY29tL2V4aWYvMS4wL2F1eC8iCiAgICB4bWxuczpleGlmRVg9Imh0dHA6Ly9jaXBhLmpwL2V4aWYvMS4wLyIKICAgIHhtbG5zOnRpZmY9Imh0dHA6Ly9ucy5hZG9iZS5jb20vdGlmZi8xLjAvIgogICBhdXg6TGVucz0iIgogICBleGlmRVg6R2FtbWE9IjExLzUiCiAgIGV4aWZFWDpMZW5zTW9kZWw9IiIKICAgdGlmZjpJbWFnZUxlbmd0aD0iMjMiCiAgIHRpZmY6SW1hZ2VXaWR0aD0iMzIiLz4KIDwvcmRmOlJERj4KPC94OnhtcG1ldGE+Cjw/eHBhY2tldCBlbmQ9InIiPz7aGRGBAAABgmlDQ1BzUkdCIElFQzYxOTY2LTIuMQAAKJF1kc8rRFEUxz8zyMSIolhIk4aNHw1qYqPMJJQ0jVEGm5k3v9T8eL03k2SrbKcosfFrwV/AVlkrRaRkYWVNbNBz3szUTDLndu753O+953TvuWANJJWUXuuCVDqr+ac8jsXgkqP+BRvttNFFX0jR1Qmfb5aq9nmPxYy3A2at6uf+tcZIVFfAYhMeV1QtKzwtPLuWVU3eEW5TEqGI8JlwvyYXFL4z9XCRX02OF/nbZC3g94K1RdgRr+BwBSsJLSUsL8eZSuaU0n3Ml9ij6YV5id3inej4mcKDgxkm8eJmiDGZ3QwwzKCsqJLvKuTPkZFcRWaVdTRWiZMgS7+oOakelRgTPSojybrZ/7991WMjw8Xqdg/UPRvGew/Ub8NP3jC+jgzj5xhqnuAyXc7PHMLoh+j5suY8gOZNOL8qa+FduNiCjkc1pIUKUo24NRaDt1NoCkLrDTQsF3tW2ufkAQIb8lXXsLcPvXK+eeUXf8xn8VU132oAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAEeSURBVDiNnZHNLgRREEbv/BAiEm9kOS/ARmywICGsiI2HQCwkQpDYSDyASLwV0X3rHIvpmXR62gzOtm6d+1VVShU5535EdNMfKcuyk3PuTxSAC+ANmPutDOiq9+p9URSdlFJKEbGk3jgE9VGdKVU76rmaq95ndSWpZ5VoDPAELExJNqde2QC4TMCmWjZqAbyqE7upkt3Vko171MOk9iNit0Uq8AL06jLguvmukh2r8/WfD9qkEfEOLAOL6sMPspO2JXeBdfVzMigvwG3VXKcA9tpWM5aqe+pHS5ImX+qp2muVNa64oRZTZFndnykakXPuRcQa0BxxxM6PY05D3apGG1GqR2rnz7KadODwUF8Rsf1v0YiccxdYBQYRMfMA3yeJK7911CGvAAAAAElFTkSuQmCC);
    padding: .5em;
    padding-right: 1.5em;
  line-height: 1.5em;
}
// Modifies the navbar collapse point
/*
.navbar-collapse {
  overflow-x: visible;
  padding-right: @navbar-padding-horizontal;
  padding-left:  @navbar-padding-horizontal;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255,255,255,.1);
  &:extend(.clearfix all);
  -webkit-overflow-scrolling: touch;

  &.in {
    overflow-y: auto;
  }

  @media (min-width: 1100px) {
    width: auto;
    border-top: 0;
    box-shadow: none;

    &.collapse {
      display: block !important;
      height: auto !important;
      padding-bottom: 0; // Override default setting
      overflow: visible !important;
    }

    &.in {
      overflow-y: visible;
    }

    // Undo the collapse side padding for navbars with containers to ensure
    // alignment of right-aligned contents.
    .navbar-fixed-top &,
    .navbar-static-top &,
    .navbar-fixed-bottom & {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.navbar-fixed-top,
.navbar-fixed-bottom {
  .navbar-collapse {
    max-height: @navbar-collapse-max-height;

    @media (max-device-width: @screen-xs-min) and (orientation: landscape) {
      max-height: 200px;
    }
  }
}


// Both navbar header and collapse
//
// When a container is present, change the behavior of the header and collapse.

.container,
.container-fluid {
  > .navbar-header,
  > .navbar-collapse {
    margin-right: -@navbar-padding-horizontal;
    margin-left:  -@navbar-padding-horizontal;

    @media (min-width: 1100px) {
      margin-right: 0;
      margin-left:  0;
    }
  }
}

.navbar-toggle {
  position: relative;
  float: right;
  margin-right: @navbar-padding-horizontal;
  padding: 9px 10px;
  .navbar-vertical-align(34px);
  background-color: transparent;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  border-radius: @border-radius-base;

  // We remove the `outline` here, but later compensate by attaching `:hover`
  // styles to `:focus`.
  &:focus {
    outline: 0;
  }

  // Bars
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
  }
  .icon-bar + .icon-bar {
    margin-top: 4px;
  }

  @media (min-width: 1100px) {
    display: none;
  }
}


// Navbar nav links
//
// Builds on top of the `.nav` components with its own modifier class to make
// the nav the full height of the horizontal nav (above 768px).

.navbar-nav {
  margin: (@navbar-padding-vertical / 2) -@navbar-padding-horizontal;

  > li > a {
    padding-top:    10px;
    padding-bottom: 10px;
    line-height: @line-height-computed;
  }

  @media (max-width: 1099px) {
    // Dropdowns get custom display when collapsed
    .open .dropdown-menu {
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
      > li > a,
      .dropdown-header {
        padding: 5px 15px 5px 25px;
      }
      > li > a {
        line-height: @line-height-computed;
        &:hover,
        &:focus {
          background-image: none;
        }
      }
    }
  }

  // Uncollapse the nav
  @media (min-width: 1100px) {
    float: left;
    margin: 0;

    > li {
      float: left;
      > a {
        padding-top:    @navbar-padding-vertical;
        padding-bottom: @navbar-padding-vertical;
      }
    }
  }
}
*/

/*
@media (max-width: 1100px) {
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}
*/

.btn-warning {
  background-color: #ff8000;
}

.label-primary {
  background-color: @me-main-color;
}

#num-hotels {
  font-size: 18px;
  margin-left: 10px;
}

#num-hotels-modal {
  font-size: 18px;
  float: right;
  margin-right: 14px;
}

#main-container {
  padding: 6px 0 0 0;
  @media (min-width: @screen-md-min) {
    padding: 20px 0 32px 0;
  }
}

#main-container-find {
  padding: 0 0 0 0;
  @media (min-width: @screen-md-min) {
    padding: 20px 0 32px 0;
  }
}

#search-box {
  p.lead {
    font-size: 22px;
  }
  @media (max-width: @screen-xs-max) {
    color: white;
    p {
      font-size: 20px;
    }
  }
  text-align: center;
  //padding-bottom: 32px;
  color: white;
  //height: 160px;
  //@media (min-width: @screen-md-min) {
  //  height: 300px;
  //}

  .input-group {
    padding: 0 24px;
    @media (min-width: @screen-md-min) {
      padding: 0 120px;
    }
  }
}

#search-input {
  border-radius: 0;
  float: left;
  width: 70%;
  @media (max-width: @screen-xs-max) {
    font-size: 14px;
    width: 100%
  }
}

#search-input-fp {
  border-radius: 0;
  float: left;
  width: 80%;
  @media (max-width: @screen-xs-max) {
    font-size: 14px;
    width: 100%
  }
}

#search-bar {
  padding-bottom: 20px;
}

#search-bar .input-group-addon {
  border: 1px solid #269abc;
  border-radius: 0;
}

#search-box h2 {
  margin-bottom: 60px;
}

#search-box p {
  margin-bottom: 32px;
  @media (max-width: @screen-xs-max) {
    margin-bottom: 6px;
  }
}

#search-box .btn-info, #search-bar .btn-info {
  background-color: @me-main-color;
  border-radius: 0;
}

#search-box .btn-info:hover {
  background-color: @me-main-color-darker;
}

#search-results-box {

}

.search-filter {
  padding-bottom: 12px;
  @media (max-width: @screen-xs-max) {
    padding-bottom: 12px;
  }
  @media (max-width: @screen-md-max) {
    .navbar-collapse {
      margin-top: 0;
    }
  }
}

#main-info-row > .row {
  padding: 0 32px;
  margin-top: 32px;
}

#main-info-row .panel {
  border: 0;
}

#main-info-row .panel-heading {
  border: 0;
  background-color: @me-main-color;
  color: #fff;
}

#main-info-row .panel-body {
  color: #888;
}

.form-control {
  color: @gray-dark;
}

// Spinner

.glyphicon.spinning {
  animation: spin 1s infinite linear;
  -webkit-animation: spin2 1s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.btn-primary {
  background-color: @me-main-color;
}

.btn-primary:hover {
  background-color: @me-main-color-darker;
}

.btn-offer {
  background-color: #8dcaa8;
  border-color: #ffffff;
  border-width: 4px;
  border-radius: 8px;
  &:hover {
    background-color: #7cb997;
    border-color: #eeeeee;
  }
}

.btn-selected-hotels {
  background-color: #8dcaa8;
  color: white;
  /*
  border-color: #ffffff;
  border-width: 4px;
  border-radius: 8px;
  */
  width: 100%;
  &:hover {
    background-color: #7cb997;
    //border-color: #eeeeee;
    color: white;
  }
}

.input-group-addon {
  color: white;
  background-color: @me-main-color;
}

/**
.panel {
  @media (max-width: @screen-xs-max) {
    border: 0;
    background-color: #f9f9f9;
  }
}*/

.panel-body {
  @media (min-width: @screen-md-min) {
    margin-left: 8px;
    margin-right: 8px;
  }

  h3 {
    padding-bottom: 18px;
    border-bottom: 1px solid #dddddd;
  }

  @media (max-width: @screen-xs-max) {
    background-color: #f9f9f9;
  }
}

.breadcrumb {
  background-color: @me-bg-color;
  @media (max-width: @screen-xs-max) {
    padding: 16px 15px 12px 15px;
    margin-bottom: 0;
  }
}

#request-detail-sidebar {
  .panel-heading {
    &:hover {
      background-color: #eeeeee;
    }
    a {
      text-decoration: none;
      color: #555555;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .panel.panel-primary {
    .panel-heading {
      background-color: @me-main-color;
      &:hover {
        background-color: @me-main-color-darker;
      }
      a {
        color: #ffffff;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.list-group-item.active, .list-group-item.active:hover {
  background-color: @me-main-color;
}

.dialogue-box {
  border: 1px solid #dddddd;
  padding: 8px;
}

.dialogue-box-body {
  padding: 6px;
}

.dropdown-menu > li {
  position: relative;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
  cursor: pointer;
}

.dropdown-menu .sub-menu {
  left: 100%;
  position: absolute;
  top: 0;
  display: none;
  margin-top: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  //border-left-color:#fff;
  box-shadow: none;
}

.right-caret:after, .left-caret:after {
  content: "";
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  display: inline-block;
  height: 0;
  vertical-align: middle;
  width: 0;
  margin-left: 5px;
}

.right-caret:after {
  border-left: 5px solid #ffaf46;
}

.left-caret:after {
  border-right: 5px solid #ffaf46;
}

#favourite-star {
  line-height: 36px;
}

#user-profile-panel {
  @media (min-width: @screen-md-min) {
    padding: 20px;
  }
  .tab-pane {
    margin-top: 20px;
  }
}

#user-profile-panel-body {
  @media (max-width: @screen-sm-max) {
    padding: 20px 0 0 0;
  }
}

.profile-panel-body {
  @media (max-width: @screen-sm-max) {
    padding: 12px 32px 0 32px;
    background-color: white;
  }
}

.nav-pills > li.active > a {
  background-color: @me-main-color;
}

.nav-pills > li.active > a:hover {
  background-color: @me-main-color-darker;
}

.nav-row {
  padding: 12px 0;
}

.triangle-right {
  position: relative;
  padding: 15px;
  margin: 0 0 2em;
  color: #444;
  background: #9be86d; /* default background for browsers without gradient support */
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

/* Variant : for top positioned triangle
------------------------------------------ */

.triangle-right.top {
}

/* Variant : for left positioned triangle
------------------------------------------ */

.triangle-right.left {
  margin-left: 40px;
  background: #9be86d;
  &.private-message {
    background: pink;
  }
}

/* Variant : for right positioned triangle
------------------------------------------ */

.triangle-right.right {
  margin-right: 40px;
  background: #9be86d;
  &.private-message {
    background: pink;
    border-color: pink transparent;
  }
}

/* THE TRIANGLE
------------------------------------------------------------------------------------------------------------------------------- */

.triangle-right:after {
  content: "";
  position: absolute;
  bottom: -20px; /* value = - border-top-width - border-bottom-width */
  left: 50px; /* controls horizontal position */
  border-width: 20px 0 0 20px; /* vary these values to change the angle of the vertex */
  border-style: solid;
  border-color: #9be86d transparent;
  /* reduce the damage in FF3.0 */
  display: block;
  width: 0;
  &.private-message {
    border-color: pink transparent;
  }
}

/* Variant : top
------------------------------------------ */

.triangle-right.top:after {
  top: -20px; /* value = - border-top-width - border-bottom-width */
  right: 50px; /* controls horizontal position */
  bottom: auto;
  left: auto;
  border-width: 20px 20px 0 0; /* vary these values to change the angle of the vertex */
  border-color: transparent #9be86d;
  &.private-message {
    border-color: transparent pink;
  }
}

/* Variant : left
------------------------------------------ */

.triangle-right.left:after {
  top: 16px;
  left: -40px; /* value = - border-left-width - border-right-width */
  bottom: auto;
  border-width: 15px 40px 0 0; /* vary these values to change the angle of the vertex */
  border-color: transparent #9be86d;
  &.private-message {
    border-color: transparent pink;
  }
}

/* Variant : right
------------------------------------------ */

.triangle-right.right:after {
  top: 16px;
  right: -40px; /* value = - border-left-width - border-right-width */
  bottom: auto;
  left: auto;
  border-width: 15px 0 0 40px; /* vary these values to change the angle of the vertex */
  border-color: transparent #9be86d;
}

.triangle-right.right.private-message:after {
    border-color: transparent pink;
}

.triangle-right-o {
  position: relative;
  padding: 15px;
  margin: 0 0 2em;
  color: #444;
  background: #dddddd; /* default background for browsers without gradient support */
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

/* Variant : for top positioned triangle
------------------------------------------ */

.triangle-right-o.top {
}

/* Variant : for left positioned triangle
------------------------------------------ */

.triangle-right-o.left {
  margin-left: 40px;
  background: #dddddd;
  &.private-message {
    background: pink;
  }
}

/* Variant : for right positioned triangle
------------------------------------------ */

.triangle-right-o.right {
  margin-right: 40px;
  background: #dddddd;
  &.private-message {
    background: pink;
  }
}

/* THE TRIANGLE
------------------------------------------------------------------------------------------------------------------------------- */

.triangle-right-o:after {
  content: "";
  position: absolute;
  bottom: -20px; /* value = - border-top-width - border-bottom-width */
  left: 50px; /* controls horizontal position */
  border-width: 20px 0 0 20px; /* vary these values to change the angle of the vertex */
  border-style: solid;
  border-color: #dddddd transparent;
  /* reduce the damage in FF3.0 */
  display: block;
  width: 0;
  &.private-message {
    border-color: pink transparent;
  }
}

/* Variant : top
------------------------------------------ */

.triangle-right-o.top:after {
  top: -20px; /* value = - border-top-width - border-bottom-width */
  right: 50px; /* controls horizontal position */
  bottom: auto;
  left: auto;
  border-width: 20px 20px 0 0; /* vary these values to change the angle of the vertex */
  border-color: transparent #dddddd;
  &.private-message {
    border-color: transparent pink;
  }
}

/* Variant : left
------------------------------------------ */

.triangle-right-o.left:after {
  top: 16px;
  left: -40px; /* value = - border-left-width - border-right-width */
  bottom: auto;
  border-width: 15px 40px 0 0; /* vary these values to change the angle of the vertex */
  border-color: transparent #dddddd;
  &.private-message {
    border-color: transparent pink;
  }
}

/* Variant : right
------------------------------------------ */

.triangle-right-o.right:after {
  top: 16px;
  right: -40px; /* value = - border-left-width - border-right-width */
  bottom: auto;
  left: auto;
  border-width: 15px 0 0 40px; /* vary these values to change the angle of the vertex */
  border-color: transparent #dddddd;
  &.private-message {
    border-color: transparent pink;
  }
}

.triangle-right-o.left.private-message:after {
    border-color: transparent pink;
}

#search_map {
  margin-top: 16px;
  width: 100%;
  height: 312px;
  @media (min-width: @screen-md-min) {
    height: 642px;
  }
}

#search-map {
  margin-top: 16px;
  width: 100%;
  height: 312px;
  @media (min-width: @screen-md-min) {
    height: 642px;
  }
}

#search-results-info-box {
  padding-top: 16px;
  font-size: 18px;
  color: #888888;
}

.action-section {
  display: none;
  margin-top: 16px;
}

#ask-us-link {
  color: @me-alert-color;
}


#main-info-container {
  h4 {
    padding-top: 16px;
    padding-bottom: 8px;
    color: white;
  }
  hr {
    border-color: #777;
    padding-bottom: 0;
    border-width: 2px;
  }
  margin: 32px auto 0 auto;
  width: 100%;
  background-color: #444;
  color: #eee;
  //position: absolute;
  padding-top: 14px;
  padding-bottom: 52px;

  @media (min-width: @screen-md-min) {
    margin: 100px auto 0 auto;
    padding-bottom: 36px;
  }

  /* Set the fixed height of the footer here */
  //height: 290px;
  a {
    &:hover {
      text-decoration: none;
    }
  }
  i {
    // This is a hack to get a white icon background
    // See http://stackoverflow.com/questions/26516353/font-awesome-background-color
    background-color: white;
    border-radius: 25%;
    height: 0.85em;
    width: 0.85em;
    line-height: 25px;
  }
  img {
    width: 120px;
  }
  p.lead {
    color: #aaaaaa;
    padding-top: 14px;
    padding-bottom: 16px;
    @media (min-width: @screen-md-min) {
      padding-bottom: 16px;
    }
  }
}

.social-media-icon {
  margin: 1px;
  //padding: 40px 6px 40px 6px;
  //color: @me-main-color;
  //color: white;
  &:hover {
    text-decoration: none;
    color: @me-main-color-darker;
  }
}

a.newsletter-link {
  padding: 4px 8px;
  position: relative;
  top: -5px;
  background-color: @me-main-color;
  color: white;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  &:hover {
    color: white;
    text-decoration: none;
    background-color: @me-main-color-darker;
  }

}

a.newsletter-link {
  &:hover {
    color: white;
    text-decoration: none;
  }
}


.hotel-conference-rooms-panel {
  h4 {
    margin-bottom: 32px;
  }
}

.panic-button {
  color: white;
  background-color: @me-alert-color;
  margin-top: 12px;
  padding: 0 12px 0 36px;
  &:hover {
    color: white;
    text-decoration: none;
    background-color: @me-alert-color-darker;
  }
}

#hotel-description-box {
  padding: 0 0 0 0;
  //text-align: justify;
  @media (min-width: @screen-md-min) {
    padding: 26px 30px;
  }
  h2 {
    font-size: 18px;
    font-weight: bold;
  }
  h3 {
    font-size: 16px;
  }
}

.facility-box {
  width: 40px;
  height: 40px;
  margin-top: 8px;
  padding-right: 0;
  img {
    width: 36px;
  }
  p {
    font-size: 70%;
    padding: 0;
    margin: 1px 1px;
  }
}

.facility-box-green-key {
  width: 40px;
  height: 40px;
  margin-top: 14px;
  padding-right: 0;
  img {
    width: 30px;
    padding-top: 0;
  }
  p {
    font-size: 70%;
    padding: 0;
    margin: 1px 1px;
  }
}

h1, h2, h3, h4 {
  color: @me-main-color;
  padding-bottom: 16px;
}

h2 {
  @media (max-width: @screen-sm-max) {
    font-size: 20px;
    padding-bottom: 2px;
  }
}

.meeting-room-facility-box {
  img {
    height: 36px;
    width: 36px;
  }
}

.meeting-rooms-table {
  thead > tr.header > th {
    border: 1px solid @me-main-color;
  }
  th {
    background-color: @me-main-color;
    color: white;
  }
  tr.borderless {
    td {
      border: none;
    }
  }
  i {
    color: @me-main-color;
  }
}

.panel-staff {
}

.edit-icon-link, .favorite-icon-link, .map-expand-icon-link {
  color: @me-main-color;
  &:hover {
    color: @me-main-color-darker;
  }
}

.offer-pdf {
  h1 {
    background-color: @me-main-color;
  }
}

.inspiration-box {
  img {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  h4 {
    padding-bottom: 0;
  }
  a {
    color: #888;
    &:hover {
      text-decoration: none;
      color: #555;
    }
    i {
      margin-left: 10px;
      color: @me-main-color;
      &:hover {
        text-decoration: none;
        color: @me-main-color-darker;
      }
    }
  }
}

.front-page-box {
  width: 320px;
  //width: 280px;
  height: 268px;
  background-color: #eeeeee;
  margin-bottom: 32px;
  a {
    display: block;
    width: 100%;
    height: 100%;
    color: black;
  }
  p {
    font-size: 85%;
  }
  i {
    margin-left: 10px;
    color: @me-main-color;
    &:hover {
      text-decoration: none;
      color: @me-main-color-darker;
    }
  }
}

.front-page-box-clickable {
  &:hover {
    img {
      opacity: .75;
    }
    background-color: #dddddd;
    a {
      text-decoration: none;
    }
  }
}

.article-box {
  h1 {
    padding-top: 8px;
    font-size: 24px;
  }
  h2 {
    padding-top: 4px;
    font-size: 20px;
    padding-bottom: 4px;
  }
  h3, h4 {
    padding-bottom: 0;
    margin-top: 16px;
    margin-bottom: 8px;
    font-size: 16px;
  }
  p.published {
    color: #888;
    margin-bottom: 18px;
  }
  .subtitle {
    padding-bottom: 10px;
    font-size: 1.2em;
  }
  a {
    color: @me-main-color;
  }
  a.btn {
    color: white;
  }
  padding-bottom: 42px;
}

.fill-div {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  img:hover {
    opacity: .75;
  }
}

.presentation-row {
  margin-bottom: 42px;
}

.arrow-row {
  margin-bottom: 32px;
}

.contact-row {
  background-color: #ffffff;
  padding: 40px 40px 40px 20px;
  margin-bottom: 16px;
  .media {
    @media (min-width: @screen-sm-min) {
      border-left: 1px solid #ddd;
    }
  }
  .media-body {
    @media (min-width: @screen-sm-min) {
      padding-left: 20px;
    }
    @media (max-width: @screen-xs-max) {
      padding-top: 24px;
    }
  }
  img {
    padding: 0 16px 0 0;
  }
  border: 1px solid #dddddd;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

#back-to-search-button {
  padding-bottom: 10px;
  float: right;
  bottom: 10px;
  margin-top: 20px;
}

.readmore-link {
  margin: 14px 0;
  @media (min-width: @screen-md-min) {
    padding-top: 0;
    margin: 20px 30px;
  }
}

#hotel_map {
  width: 100%;
  height: 260px;
  margin-right: 0;
  float: left;
  margin-top: 12px;
  @media (min-width: @screen-md-min) {
    width: 100%;
    height: 235px;
    margin-right: 16px;
  }
  @media (min-width: @screen-lg-min) {
    width: 100%;
    height: 308px;
    margin-right: 16px;
  }
}

#map-expand-link {
  margin-top: 12px;
  margin-bottom: 20px;
  float: left;
  @media (min-width: @screen-md-min) {
    float: left;
  }
}

.facility-row {
  padding: 0 0 10px 0;
  @media (min-width: @screen-md-min) {
    padding: 0 14px 10px 0;
  }
}

.add-direct-row {
  padding-top: 16px;
  @media (min-width: @screen-md-min) {
    margin-bottom: 16px;
    padding-top: 32px;
  }
  button {
    width: 100%;
  }
}

.campaign-row, .news-row {
  padding-bottom: 25px;
  p {
    @media (max-width: @screen-sm-max) {
      margin: 0 -26px 10px 0;
    }
  }
  img {
    @media (max-width: @screen-sm-max) {
    }
  }
  @media (max-width: @screen-sm-max) {
    margin-left: -14px;
  }
}

.padded-box {
  @media (max-width: @screen-sm-max) {
    padding: 18px;
  }
}

.news-image-box {
  padding: 26px 0 12px 14px;
  @media (min-width: @screen-md-min) {
    padding: 58px 40px 0 32px;
  }
}

.profile-nav {
  @media (max-width: @screen-sm-max) {
    margin-bottom: 18px;
  }
}

.hotel-dialogue-action-row {
  a {
    font-size: 12px;
  }
  i {
    padding-right: 8px;
  }
  @media (max-width: @screen-md-max) {
    div {
      margin-bottom: 4px;
    }
  }
}

.dialogue-action-row {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  color: @me-main-color;
  i {
    margin-right: 10px;
  }
  div {
    margin-bottom: 10px;
  }
}

.user-dialogue-action-row {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  color: @me-main-color;
  i {
    margin-right: 10px;
  }
  div {
    margin-bottom: 6px;
  }
  .panel-body {
    padding: 15px 15px 6px 15px;
  }
}

.campaign-button-panel {
  .panel-body {
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 14px;
    i {
      padding-right: 12px;
    }
    .btn {
      padding: 16px 80px 16px 40px;
      margin-right: 12px;
    }
    @media (max-width: @screen-xs-max) {
      padding-left: 12px;
      .btn {
        padding: 16px 40px 16px 20px;
      }
    }
  }
}

#offer-howto-button {
  width: 100%;
  text-align: left;
}

#request-ask-meetings-button {
  width: 100%;
  text-align: center;
  background-color: #e37cb1;
  border-color: #da63a1;
  &:hover {
    background-color: #da63a1;
  }
}

#ask-us-button {
  text-align: left;
  background-color: @me-main-color;
}

#chat-button {
  text-align: left;
  // background-color: @me-main-color;
  margin-bottom: 20px;
}

#hotel-chat-button {
  text-align: left;
  margin-top: 16px;
}

#slogan {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.4;
  margin-top: 10px;
  color: white;
  text-align: center;
  a {
    color: #ffffff;
    &:hover {
      text-decoration: none;
      color: #bbbbbb;
    }
  }
  @media (max-width: @screen-xs-max) {
    font-size: 18px;
  }
}

#slogan-row {
  padding-bottom: 24px;
  height: 40px;
  @media (min-width: @screen-md-min) {
    height: 130px;
  }
}

#carousel-mobile-frontpage {
  //margin-top: 20px;
  .carousel-control.left, .carousel-control.right {
    background: none !important;
    filter: none !important;
    progid: none !important;
  }

  h5 {
    margin-bottom: 4px;
  }
  .carousel-inner {
    .item {
      img {
        width: 100%;
      }
    }
  }
  .carousel-caption {
    bottom: 16px;
  }
}


#recommendations {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
  margin-top: 10px;
  color: #888888;
  text-align: center;
  a {
    color: #888888;
    &:hover {
      text-decoration: none;
      color: #555555;
    }
  }
}

#recommendations-row {
  padding-bottom: 32px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 0;
  @media (min-width: @screen-md-min) {
    padding-left: 80px;
    padding-right: 80px;
  }
}

#mc_embed_signup {
  // background: #fff;
  clear: left;
  font: 14px Helvetica, Arial, sans-serif;
}

#previous-articles-sidebar {
  padding-top: 32px;
  padding-left: 8px;
  .list-group {
    margin-bottom: 8px;
  }
  a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  @media (max-width: @screen-xs-max) {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 14px;
  }
}

#article-list-page-link {
  padding-left: 17px;
}

#destinations-list-box {
  h2 {
    padding-left: 9px;
  }
}

#destinations-list {
  li {
    @media (min-width: @screen-md-min) {
      position: relative;
    }
    padding: 8px 8px 4px 8px;
  }
  .media-body {
    padding-left: 16px;
  }
  small {

    @media (min-width: @screen-md-min) {
      position: absolute;
      bottom: 4px;
      right: 8px;
    }
  }
  h4 {
    padding-bottom: 4px;
  }
  a {
    text-decoration: none;
    color: #555555;
    &:hover {
      text-decoration: none;
    }
  }
  :hover {
    background-color: @me-bg-color-darker;
  }
  p {
    @media (max-width: @screen-sm-max) {
      display: none;
    }
  }
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#nav-description {
  font-size: 16px;
  color: #444444;
  padding-bottom: 18px;
  padding-top: 0;
  div h4 {
    margin-top: 0;
  }
  div .image-caption {
    position: relative;
    top: -20px;
    left: 6px;
    font-size: small;
    color: white;
  }
}

.table-header {
  white-space: nowrap;
}

.table-order-header {
  background-color: #eeeeee;
  margin: 5px;
}

#edit-offer-button {
  width: 100%;
}

#upload-contract-button {
  width: 100%;
}

#chart {
    @media (max-width: @screen-md-max) {
      display: none;
    }
}

#social-media-row {
  text-align: center;
  @media (min-width: @screen-sm-max) {
    padding-left: 40px;
    text-align: left;
  }
}

#shopping-cart {
  float: right;
  font-size: 120%;
  padding-right: 10px;
  color: @me-main-color;
  &:hover {
    text-decoration: none;
    color: @me-main-color-darker;
  }
}

#shopping-cart-text {
  float: right;
  font-size: 80%;
  color: @me-main-color;
  padding-right: 10px;
  &:hover {
    text-decoration: none;
    color: @me-main-color-darker;
  }
}

#num-hotels-modal.label-primary {
  //color: @me-main-color;
  &:hover {
    text-decoration: none;
    background-color: @me-main-color-darker;
  }
}
//i#shopping-cart.fa.fa-shopping-cart.fa-2x {
//  position: relative;
//  //float: right;
//
//  //top: 0;
//  font-size: 40%;
//  padding: .6em;
//  //border-radius: 999px;
//  line-height: .75em;
//  color: @me-primary-color;
//  text-align: center;
//  min-width: 2em;
//  font-weight: bold;
//  background: white;
//  border-style: solid;
//}

#selected-hotel-modal-title-content {
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.room-type-cell {
  background-color: white;
  margin: 2px;
  padding: 12px;
  font-size: 12px;
  height: 100%;
  border: 1px solid #ccc;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    text-decoration: none;
    background-color: #eeeeee;
  }
}

.room-type-row {
  padding-left: 12px;
  padding-right: 12px;
  @media (max-width: @screen-sm-max) {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

table.request-table {
  thead {
    tr {
      th {
        border: 1px solid @me-bg-color;
        background-color: @me-main-color;
        color: white;
        vertical-align: middle;
      }
    }
  }
  td {
    border: 1px solid @me-bg-color;
  }
  tr {
    background-color: #f0f0f0;
    line-height: 50px;
    min-height: 50px;
    height: 50px;
  }
  /**
  tr:nth-child(odd) {
    background-color: #ddddee;
    &:hover {
      background-color: #ccccdd;
    }
  }
  */
  tr:hover {
    background-color: #dddddd;
  }
  tr.request-table-selected {
    background-color: #c8e8b1;
  }
  tr.request-table-selected:hover {
    background-color: #abe876;
  }
}

.request-panel {
  padding: 8px;
}

.request-panel-selected {
  background-color: #c8e8b1;
}

.request-panel-not-selected {
  background-color: #eeeeee;
}

.sane-word-break {
  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for webkit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.login-button {
  position: absolute;
  top: 10px;
  right: 110px;
  padding-top: 10px;
  padding-bottom: 0;
  padding-right: 0;
  text-align: right;
  .btn {
    padding: 4px 12px;
  }
  @media (max-width: @screen-md-min) {
    right: 70px;
  }
  @media (max-width: @screen-sm-min) {
    right: 40px;
  }
}

.contact-us-box {
  margin: 6px 3px 10px 3px;
  border: 5px solid @me-main-color;
  font-size: 18px;
  @media (max-width: @screen-xs-max) {
    margin-top: 8px;
  }
  @media (max-width: @screen-md-max) {
    margin-top: 8px;
    p {
      font-size: 14px;
    }
  }
  @media (min-width: @screen-sm-max) {
    p {
      margin-top: 10px;
    }
    height: 110px;
  }
}

.ask-us-box {
  margin: 4px;
  border: 5px solid @me-main-color;
  background-color: @me-main-color;
  &:hover {
    border-color: @me-main-color-darker;
    background-color: @me-main-color-darker;
  }
  .media-body {
    width: 70%;
    text-align: center;
    padding-top: 12px;
    padding-right: 10px;
    p {
      font-size: 22px;
      font-weight: bold;
      color: white;
      margin-bottom: 6px;
    }
  }
}

#contact-us-button {
  margin-top: 6px;
  margin-bottom: 12px;
  width: 100%;
  @media (max-width: @screen-sm-max) {
    margin-top: 0;
  }
}

.employee-box-panel {
  @media (min-width: @screen-sm-min) {
    height: 490px;
  }
  @media (min-width: @screen-md-min) {
    height: 330px;
  }
  @media (min-width: @screen-lg-min) {
    height: 376px;
  }
}

.employee-box-header {
  border-bottom: 2px solid @me-main-color;
  padding-bottom: 6px;
  margin-bottom: 16px
}

#upper-main-container {
  @media (max-width: @screen-xs-max) {
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 76px;
  }
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  @media (max-width: @screen-xs-max) {
    padding-left: 0;
    padding-right: 0;
    min-height: 0;
  }
}

.hotel-detail-lead {
  @media (max-width: @screen-xs-max) {
    padding: 18px;
  }
}

.generic-page-text {
  p {
    padding-top: 8px;
  }
  h4 {
    margin-top: 16px;
    margin-bottom: 12px;
    padding-bottom: 2px;
  }
}

#newsletter-subscription-box {
  margin-top: 20px;
  margin-bottom: 14px;
  background-color: @me-main-color;
  color: white;
  h4 {
    padding-top: 14px;
    color: white;
  }
  form {
    padding: 0 18px;
  }
  p {
    margin-top: 14px
  }
  a {
    color: white;
  }
  button {
    width: 100%;
    margin-bottom: 20px;
  }
}

#front-page-search-row {
  background-color: @me-main-color;
  position: absolute;
  top: 64px;
  height: 240px;
  width: 100%;
  z-index: -5;
  @media (min-width: @screen-md-min) {
    height: 190px;
  }
}

#front-page-search-row-digital {
  &:extend(#front-page-search-row);
  background-color: #CA4D20;
}

.cta-button {
  margin-top: 10px;
  @media (min-width: @screen-sm-min) {
    float: right;
    margin-top: 0;
  }
}

#cta-header {
  @media (min-width: @screen-sm-min) {
    br {
      display: none;
    }
  }
  @media (max-width: @screen-xs-max) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.request-form {
  @media (max-width: @screen-sm-max) {
    padding: 14px;
  }
}

#request-filter {
  @media (min-width: @screen-md-min) {
    float: right !important;
  }
}

.request-form-button {
  margin-top: 6px;
  background-color: #8dcaa8;
  &:hover {
    background-color: #7cb997;
  }
}

.request-form-button-red {
  margin-top: 6px;
  background-color: #ff0000;
}

.carousel-caption-credits {
  position: absolute;
  bottom: 2%;
  text-align: right;
  padding-right: 20px;
  width: 100%;
  color: white;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.carousel-caption {
  bottom: 0;
  left: 5%;
  right: 5%;
}

.carousel-indicators {
  bottom: 0;
}

.pagination-row {
  text-align: center;
  li {
    a {
      color: @me-main-color;
      &:hover {
        color: @me-main-color-darker;
      }
    }
    > .active {
    }
  }
  li.active {
    a {
      background-color: @me-main-color;
      color: white;
      &:hover {
        background-color: @me-main-color;
      }
    }
  }
}

.order-button {
  margin-left: 10px;
}

.order-button-danger {
  margin-left: 10px;
  background-color: #ac2925;
  border-color: #761c19;
}

.navbar-nav {
  @media (max-width: @screen-md-max) {
    // Dropdowns get custom display when collapsed
    .open .dropdown-menu {
      position: absolute;
      float: none;
      width: auto;
      left: -250px;
      top: 30px;
      margin-top: 0;
      background-color: white;
      border: 1px solid #cccccc;
      box-shadow: none;

      > li > a,
      .dropdown-header {
        padding: 5px 15px 5px 25px;
      }

      > li > a {
        line-height: @line-height-computed;

        &:hover,
        &:focus {
          background-image: none;
        }
      }
    }
  }
}

.nav-selected-hotels {
  position:absolute;
  padding-top: 1px;
  top: 24px;
  right: 200px;
  @media (max-width: @screen-md-min) {
    right: 130px;
  }
}

.nav-selected-hotels-text {
  @media (max-width: @screen-xs-max) {
    display: none;
  }
}

.request-action-menu {
  margin-top: 6px;
}

.change-password-panel-body {
  background-color: #ffffff;
}

#hotel-detail-header {
  background-color: @me-main-color;
  padding: 8px;
  z-index: 999;
  float: left;
  margin: 0 auto;
  /*
  width: 1170px;
  @media (max-width: @screen-xs-max) {
    width: 574px;
  }
  @media (max-width: @screen-sm-max) {
    width: 750px;
  }
  @media (max-width: @screen-md-max) {
    width: 970px;
  }
   */
  width: 100%;
  @media (min-width: @screen-sm-min) {
    width: 750px;
  }
  @media (min-width: @screen-md-min) {
    width: 970px;
  }
  @media (min-width: @screen-lg-min) {
    width: 1170px;
  }
  p {
    font-size: 30px;
    color: white;
    margin: 0 0 0 12px;
    float: left;
    @media (max-width: @screen-xs-max) {
      font-size: 16px;
      padding: 8px 0 4px 0;
      max-width: 200px;
      word-wrap: break-word;
    }
  }
  button {
    float: right;
  }
  .panel {
    margin-bottom: 16px;
  }
}

#hotel-detail-header-fake {
  height: 80px;
  width: 0;
  float: right;
}

.affix {
  top: 80px;
  position: fixed;
  @media (max-width: @screen-xs-max) {
    top: 66px;
  }
}

.search-nav-button {
  background-color: #8dcaa8;
  &:hover {
    background-color: #7cb997;
  }
}

.service-row {
  .media {
    margin-bottom: 20px;
  }
  .media:first-child {
    margin-top: 20px;
  }
}

.service-box {
  background-color: #eeeeee;
  .media-body {
    position: relative;
    .media-heading {
      padding-top: 8px;
      padding-bottom: 0;
    }
    .service-box-link-text {
      @media (min-width: @screen-sm-min) {
        position: absolute;
        bottom: 4px;
        margin-bottom: 0;
      }
      font-weight: bold;
    }
  }
  .media-left {
    padding-right: 60px;
  }
  .media-right {
    padding-left: 60px;
    position: relative;
    i {
      color: @me-main-color;
      margin: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  a {
    color: inherit;
    display: block;
  }
  a:hover, a:visited, a:link, a:active {
    text-decoration: none;
  }
  &:hover {
    background-color: #e2e2e2;
  }
}

.full-width {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
}

.image-banner {
  height: auto;
  display: block;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 20px;
  text-align: center;
  p {
    font-size: 20px;
    font-weight: bold;
    color: #111155;
    margin: 20px 0 4px;
    a {
      color: #111155;
      &:hover {
        text-decoration: none;
        color: #333377;
      }
    }
  }
}

.image-banner-front {
  padding-top: 20px;
  margin-top: 0;
  margin-bottom: 0;
  @media (min-width: @screen-sm-min) {
    margin-bottom: 80px;
  }
}

.image-banner-inner {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background: rgba(255, 255, 255, .5);
  max-width: 1170px;
  padding-bottom: 6px;
  h2 {
    padding-top: 10px;
    padding-bottom: 4px;
    color: #111155;
    @media (max-width: @screen-xs-max) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  p {
    font-size: 16px;
    font-weight: bold;
    color: #111155;
    margin: 0 0 4px
  }
}

.image-banner-inner-front {
  max-width: 800px;
  padding: 0 10px 10px 10px;
  @media (min-width: @screen-sm-min) {
    padding: 0 40px 20px 40px;
  }
}

.image-banner-inner-search {
  padding: 20px 20px 0 20px;
  @media (max-width: @screen-xs-max) {
    padding: 20px 6px 0 6px;
  }
}

.image-banner-btn {
  margin-bottom: 12px;
  border-radius: 0;
  border: 0;
}

.image-banner-phone-link {
  @media (min-width: @screen-md-max) {
    display: none;
  }
}

.testimonial-image-caption {
  margin-bottom: 10px;
  @media (min-width: @screen-sm-min) {
    width: 580px;
  }
}

.testimonial-contact-button {
  margin-bottom: 10px;
  @media (min-width: @screen-sm-min) {
    width: 580px;
  }
}

#selected-hotels-banner {
  @media (max-width: @screen-xs-max) {
    margin: 14px;
  }
}

#selected-hotels-panel {
  h4 {
    margin-top: 0;
    margin-bottom: 4px;
  }
  @media (max-width: @screen-md-max) {
    visibility: hidden;
  }
}

#selected-hotels-panel-inner {
  a {
    color: inherit;
    display: block;
    text-decoration: none;
    padding: 8px 0 8px 0;
  }

  a:hover {
    text-decoration: none;
    background-color: #f0f0f0;
  }
}

#selected-hotels-mobile-panel {
  h4 {
    margin-top: 0;
    margin-bottom: 4px;
  }

  padding: 12px;
  @media (max-width: @screen-sm-max) {
    margin-left: 28px;
    width: 692px;
  }
  @media (max-width: @screen-xs-max) {
    margin-left: 14px;
    margin-right: 14px;
    width: auto;
  }
}

#selected-hotels-mobile-panel-inner {
  div.selected-hotel-mobile-wrapper {
    margin-right: 9px;
    margin-top: 8px;
    float: left;
    width: 277px;
    @media (max-width: @screen-md-max) {
      width: 305px;
    }
    @media (max-width: @screen-sm-max) {
      width: 324px;
    }
    @media (max-width: @screen-xs-max) {
      width: auto;
    }
    a {
      color: inherit;
      display: block;
      text-decoration: none;
      padding: 0;
    }

    a:hover {
      text-decoration: none;
      background-color: #f0f0f0;
    }
    h5 {
      padding-top: 4px;
    }
  }
}

#category-dropdown {
  background-color: @me-main-color;
  height: 46px;
  a.dropdown-toggle {
    color: white;
    font-size: 18px;
  }
  a.dropdown-toggle:hover {
    text-decoration: none;
    background-color: @me-main-color;
  }
  a.dropdown-toggle:not(:hover) {
    text-decoration: none;
    background-color: @me-main-color;
  }
  .navbar-nav > li > a {
    padding-top: 13px;
    padding-bottom: 13px;
    @media (max-width: @screen-md-max) {
      padding-top: 6px;
      padding-bottom: 8px;
    }
  }
  @media (max-width: @screen-md-max) {
    display: block;
    .navbar-nav .open .dropdown-menu {
      left: 0;
      top: 40px;
    }
  }
}



.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.caret.caret-up {
  border-top-width: 0;
  border-bottom: 4px solid #fff;
}
.caret.caret-black-up {
  border-top-width: 0;
  border-bottom: 4px solid #000;
}

#year-filter-row {
    padding-left: 16px;
    @media (max-width: @screen-sm-max) {
      padding-left: 0;
    }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.selected-filter {
    &:hover {
      text-decoration: none;
      filter: brightness(95%);
    }
}

.drag {
  border: 2px pink dashed;
  background-color: rgba(255, 0, 119, 0.09);
}