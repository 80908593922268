@media print {

  @page {
    size: A4;
    margin: 0;
  }

  html, body {
    width: 1100px;
  }

  .container {
    width:100%;
  }

  /* Improve colour contrast of links */
  a:link, a:visited {
    color: #781351
  }

  /* URL */
  a:link, a:visited {
    background: transparent;
    color:#333;
    text-decoration:none;
  }

  a[href]:after {
    content: "" !important;
  }

  a[href^="http://"] {
    color:#000;
  }

  .table {
      border-collapse: collapse !important;

      td.danger,
      th.danger {
          background-color: #f2dede !important;
      }
  }

  .table > tbody > tr.success > td {
      background-color: #dff0d8 !important;
  }

  /*
  .table td {
    background-color: #000000 !important;
  }
  */

  /*
  .table td,
  .table th {
    background-color: #f00;
  }
  */

}
