ul.campaign-grid {
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  .clearfix();

  & > li {
    box-sizing: border-box;
    display: block;
    margin: 10px 0 10px 0;
    /*
    @media (min-width: @screen-md-min) {
      margin: 5px 0 5px 0;
    }
    */
    padding: 0;
    & > a,
    & > label {
      position: relative;
      box-sizing: border-box;
      display: block;
      padding: @me-list-grid-content-sidepadding;
      height: 344px;
      overflow: hidden;
      background-color: @me-list-grid-bg;
      border-top: 1px solid #eee;
      border-left: 1px solid #eee;
      border-right: @me-list-grid-border;
      border-bottom: @me-list-grid-border;
      border-radius: @me-default-radius;
      font-weight: normal;
      font-size: @font-size-base;
      color: @me-list-grid-color;

      &:hover {
        text-decoration: none;
        color: @me-list-grid-color;
        border-top-color: @me-list-grid-hover-border;
        border-left-color: @me-list-grid-hover-border;
        border-bottom-color: darken(@me-list-grid-hover-border, 6%);
        border-right-color: darken(@me-list-grid-hover-border, 6%);
      }

      .campaign-grid-title {
        position: absolute;
        left: 0;
        right: 0;
        top: 222px;
        padding: 4px @me-list-grid-content-sidepadding;
        margin: 0;
        background: rgba(0, 0, 0, .4);
        color: #fff;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .campaign-grid-image {
        box-sizing: border-box;
        margin-bottom: 8px;
        margin-left: -@me-list-grid-content-sidepadding;
        margin-right: -@me-list-grid-content-sidepadding;
        margin-top: -@me-list-grid-content-sidepadding;
        padding: 10px 0;
        height: 250px;
        text-align: center;
        overflow: hidden;

        .fa {
          margin-top: 40px;
          font-size: 90px;
        }
        img {
          box-sizing: border-box;
          max-width: 100%;
          max-height: 100%;
          height: auto;
        }
      }
      .campaign-grid-heading { // Typically a H3, H4, etc..
        margin: 0 0 8px 0;
        padding: 0;
        font-size: ceil(@font-size-base + 2px);
        font-weight: bold;
      }
      .campaign-grid-description {
        color: @text-muted;
        //font-size: @font-size-small;
        font-size: @font-size-large;
      }
      .campaign-grid-footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        padding: 4px @me-list-grid-content-sidepadding;
        //background-color: @me-list-grid-footer-bg;
        color: @me-main-color;
        //padding: 4px @me-list-grid-content-sidepadding;
        //background-color: @me-list-grid-footer-bg;
        thead > tr > th,
        tbody > tr > th,
        tfoot > tr > th,
        thead > tr > td,
        tbody > tr > td,
        tfoot > tr > td {
          padding: 0;
          line-height: 1.42857143;
          vertical-align: top;
          border-top: 1px solid #ddd;
        }
      }
      .campaign-grid-choose-button {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    & > label {
      cursor: pointer;

      // Make text selection impossible - avoids user selecting text when they click twice fast
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .list-grid-checked-icon {
        display: none;
        color: #2ECC40;
        text-shadow: #000 0 0 3px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 2px solid #2ECC40;
        background: rgba(255, 255, 255, .3);
        z-index: 200;
        font-size: 150px;
        text-align: center;
      }
      input[type="checkbox"] {
        position: absolute;
        right: 4px;
        top: 4px;
        z-index: 200;
        display: none;
      }
      input[type="checkbox"]:checked ~ .list-grid-checked-icon {
        display: block;
      }
    }

    @media (min-width: 800px) {
      float: left;
      padding-left: @me-list-grid-padding;
      padding-right: @me-list-grid-padding;
    }
    @media (min-width: 800px) and (max-width: @screen-sm-max) {
      width: (100% / 2);
    }
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      width: (100% / 3);
    }
    @media (min-width: @screen-lg-min) {
      width: (100% / 3);
    }
  }

  @media (min-width: @screen-sm-min) {
    // Using negative margins on the UL to negate the extra padding added by the leftmost and rightmost LI elements
    margin: 0 -@me-list-grid-padding 0 -@me-list-grid-padding;
  }
}
