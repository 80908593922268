ul.hotel-list {
  list-style: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  .clearfix();

  & > li {
    box-sizing: border-box;
    display: block;
    margin: 5px 0 10px 0;
    padding: 0;
    & > a,
    & > label {
      position: relative;
      box-sizing: border-box;
      display: block;
      padding: @me-list-grid-content-sidepadding;
      height: 136px;
      overflow: hidden;
      background-color: @me-list-grid-bg;
      border-top: 1px solid #eee;
      border-left: 1px solid #eee;
      border-right: @me-list-grid-border;
      border-bottom: @me-list-grid-border;
      border-radius: @me-default-radius;
      font-weight: normal;
      font-size: @font-size-base;
      color: @me-list-grid-color;

      &:hover {
        text-decoration: none;
        color: @me-list-grid-color;
        border-top-color: @me-list-grid-hover-border;
        border-left-color: @me-list-grid-hover-border;
        border-bottom-color: darken(@me-list-grid-hover-border, 6%);
        border-right-color: darken(@me-list-grid-hover-border, 6%);
      }

      .hotel-list-image {
        box-sizing: border-box;
        position: absolute;
        left: 8px;
        width: 160px;
        height: 120px;
        margin-bottom: 8px;
        //margin-left: -@me-list-grid-content-sidepadding;
        //margin-right: -@me-list-grid-content-sidepadding;
        //margin-top: -@me-list-grid-content-sidepadding;
        padding: 10px 0;
        text-align: center;
        overflow: hidden;

        .fa {
          margin-top: 40px;
          font-size: 90px;
        }
        img {
          box-sizing: border-box;
          max-width: 268px;
          width: 268px;
          //max-height: 100%;
          //height: auto;
        }
      }
      .hotel-list-heading { // Typically a H3, H4, etc..
        margin: 0 0 8px 0;
        padding: 0;
        position: absolute;
        left: 180px;
        font-size: ceil(@font-size-base + 2px);
        font-weight: bold;
      }
      .hotel-list-description {
        position: absolute;
        left: 180px;
        top: 28px;
        color: @text-muted;
        font-size: @font-size-small;
      }
      .truncate {
        @media (max-width: @screen-sm-max) {
          width: 260px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        @media (max-width: @screen-xs-max) {
          width: 110px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .hotel-list-footer {
        position: absolute;
        left: 180px;
        //right: 0;
        bottom: 0;
        width: 380px;
        margin: 0;
        color: @text-muted;
        font-size: @font-size-small;
        //padding: 4px @me-list-grid-content-sidepadding;
        //background-color: @me-list-grid-footer-bg;
        thead > tr > th,
        tbody > tr > th,
        tfoot > tr > th,
        thead > tr > td,
        tbody > tr > td,
        tfoot > tr > td {
          padding: 0;
          line-height: 1.42857143;
          vertical-align: top;
          border-top: 1px solid #ddd;
        }
      }
      .hotel-list-remove-button {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
    /*
    @media (min-width: 599px) {
      float: left;
      padding-left: @me-list-grid-padding;
      padding-right: @me-list-grid-padding;
    }
    */
  }
  /*
  @media (min-width: @screen-sm-min) {
    // Using negative margins on the UL to negate the extra padding added by the leftmost and rightmost LI elements
    margin: 0 -@me-list-grid-padding 0 -@me-list-grid-padding;
  }
  */
}

#hotel-detail-panel {
  padding: 0 0 0 0;
  .panel-body {
    margin-left: -29px;
    margin-right: -29px;
    padding: 0 0 0 0;
    @media (min-width: @screen-md-min) {
      margin-left: 0;
      margin-right: 0;
      padding: 0 20px 0 0;
    }
    div.media {
      padding-top: 12px;
    }
  }
  #hotel-detail-boxes {
    margin: 16px 10px 0 10px;
  }
  h2 {
    padding-bottom: 0;
  }
}

#hotel-campaign-panel {
  h3 {
    border: 0;
    margin-top: 0;
  }
  .btn {
    margin-top: 12px;
  }
}

#hotel-facilities-panel {
  ul {
    margin: 20px 20px;
  }
}
